<template>
  <PaymentReceiptComponent v-if="transactionId" :transaction-id="transactionId" />
</template>
<script>

import PaymentReceiptComponent from './PaymentReceiptComponent.vue';


export default {
  components: {
    PaymentReceiptComponent,
  },
  data() {
    return {
      transactionId: '',
    };
  },
  created() {
    this.transactionId = this.$route.query.transactionId
    if(!this.transactionId){
      this.$route.replace('/');
    }
  },
};
</script>